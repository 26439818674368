import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/kszol/projects/noeadogatsby/src/components/layout/default-page-layout.tsx";
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import Gallery from '../components/layout/gallery';
import Youtube from '../components/layout/youtube';
import thumbData from '../images/erreforditjuk/video/data.json';
import LightboxPageLayout from '../components/layout/lightbox-page-layout';
export const query = graphql`
  query {
    allFile(
      filter: {
        relativeDirectory: { eq: "erreforditjuk/2019" }
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
      }
    ) {
      nodes {
        base
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            layout: FULL_WIDTH
            breakpoints: [210, 270, 370, 572, 720]
          )
          original {
            width
            height
          }
        }
      }
    }
    allJson(filter: { graphql: { eq: "EF2019" } }) {
      nodes {
        resources {
          index
          title
          image {
            base
          }
        }
      }
    }
    video: allFile(
      filter: {
        relativeDirectory: { eq: "erreforditjuk/video" }
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
      }
    ) {
      nodes {
        base
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            layout: FULL_WIDTH
            breakpoints: [210, 270, 370, 572, 720]
          )
          original {
            width
            height
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Link = makeShortcode("Link");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = LightboxPageLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">






    <h1>{`Erre fordítjuk, amit adsz (2019)`}</h1>
    <h2>{`Noé állatotthon`}</h2>
    <p>{`Magyarország legnagyobb állatotthonaként 1992 óta végezzük a bajba jutott, megkínzott, balesetet szenvedett állatok mentését. Jelenleg 2 telephelyen közel 10 hektáron 1.200 kutya, cica, malac, kecske, juh, nyuszi, tengerimalac, csirke, kacsa, liba, páva, vaddisznó, mosómedve, ormányos medve, borz, ló, póni, szamár, láma, díszmadár, galamb, varjú, egerészölyv, teknős, hüllő és szarvasmarha és még sok másféle állatfaj átmeneti, vagy - sok esetben - végleges otthona.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(0, 3)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`2019-ben továbbra is az egyik legfontosabb szempont a felelős működésünk volt, pontosan ismerve a felelősségünket az általunk gondozott több mint 1200 állat jövőjével kapcsolatban. Továbbra is biztos hátteret és ezzel teljes körű egészségügyi és állatjóléti ellátást tudunk biztosítani védenceinknek és az új bekerülőknek.`}</p>
    <p>{`Sajnos az állattartási kultúrában nem jött meg a várva várt áttörés. Azt tapasztaljuk a mindennapokban, hogy egyre több a rászoruló állat, egész évben özönlöttek a gondozásunkba a súlyosan sérült, vagy nagyon beteg állatok. És az olyan betegségek, mind például a szívférgesség gyakorlatilag napi szintű problémává vált, gyakorlatilag átlagosan 10 bekerülő kutya közül 3 fertőzött :(`}</p>
    <p>{`Két rettegett betegség is újra megjelent az életünkben, a szopornyica és parvo. Fuksz, a szopornyica fertőzött kutyánk egy éve van extra szigorú karanténban, mert még mindig fertőz. Az év első felében több parvoval fertőzött kölyökkutya alom került a gondozásunkba. Sajnos nagyon sok apróságot elvesztettünk és mivel a parvo vírus a környezetben is évekig életképes, mindent félre téve egy új karantén részleget kellett építenünk, mely szó szerint tűzzel is fertőtleníthető. Emiatt lényegesen megugrottak az amúgy is hatalmas állatorvosi, gyógyszer és gyógytáp költségeink.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(3, 6)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Ezért bizony egy újabb lyukkal beljebb húztuk azt a bizonyos nadrágszíjat és továbbra is nagyon körültekintő, felelős gazdálkodással működünk. De azért természetesen megmentett állat, megsegített kisebb menhely, szívesen látott gyerekcsoportokból, valamint fejlesztésekből 2019-ben sem volt hiány. Az életmentések és NOÉs CSODÁK száma tovább nőtt!`}</p>
    <p>{`Számunkra nagyon fontos a környezet, a természet védelme. És nem csak beszélni, tenni is szeretünk érte. 2018-ban a központii nagy istálló tetejére napelemeket telepítettünk, melyeket 2019-ben sikerült tovább bővíteni.`}</p>
    <p>{`A 2019-es év egyik meghatározó problémája a kegyetlen fertőző betegségek - parvo és szopornyica - elleni küzdelem volt. Teljesen világossá vált, hogy muszáj lépnünk valamit radikálisat, mert sajnos a bekerülő kölykök nagy része parvosan érkezik, a felnőttek pedig a szopornyica miatt vannak veszélyben. Bár a NOÉ Állatotthon több modern karantén részleggel is rendelkezik, de ezek ellen a fertőző betegségek ellen szinte csak a tűz véd. Így átgondoltuk az éves terveinket és belevágtunk egy új, akár tűzzel is fertőtleníthető karanténrészleg építésébe. Mivel az élet nem áll meg, ahogy készültek a kennelek, úgy máris költöztek be a lakók. Csak decemberben nem kevesebb, mint 65 kölyök kutya érkezett a gondozásunkba.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(6, 9)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Támogatóinknak hála szinte az összes fűtendő helyiségben (pl. kórház, fertőző, boxer és bulldog menedék, macskás karantén, stb) sikerült a korábbi elavult és már csak döcögősen működő légkondicionálót új, modern, energiatakarékosra cserélnünk. Ez azért nagyon fontos, mert az állatotthonban nincs gáz, a fűtés / hűtés kérdést csak így tudjuk megoldani.`}</p>
    <p>{`Nagylelkű segítőnknek hála cicáink idén is kaptak egy hatalmas újdonságot. Sikerült megvásárolni és telepíteni egy iroda-raktár konténert. Nagyon fontos fejlesztés, mivel így a cicák örökbefogadása végre biztonságos környezetben zajlik és az élelmüket is el tudjuk helyezni.`}</p>
    <p>{`Kutyusaink gyengélkedő részlege új gipszkarton álmennyezetet kapott. És megtörtén az aktuális egészségügyi festés is. Valamint az idős kutya melegedőnket is elkezdtük felújítani. Bevezetésre került a víz, csatorna került kialakításra és fix épített kennelek készültek. Egy nagy lépés itt még hátra van, az épület alapig tartó szigetelése.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(9, 12)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Tovább zajlott az Állatotton akadálymentesítése, tekintve, hogy 8,5 hektáron kell megoldani, így van még mit tenni ez ügyben. Sikerült bővíteni a kerti kiülők számát is. Ezek a nyugodt pihenő pontok nagyon jó szolgálatot tesznek esőben, napsütésben.`}</p>
    <p>{`Idén is sikerült több fűthető faházat üzembe helyeznünk az állatotthon több pontján. Kaptak a fázós boxerek, a baba-mama részleg és több öreg kutyusunk is melegben aludhat már ezen a télen.`}</p>
    <p>{`MacskaLand részlegünket is továbbfejlesztettük. Egyik fő támogatónk, a Prevital segítségével egy óriási mászókát hódíthatnak meg és több hőszigetelt cicaház is a rendelkezésükre áll.`}</p>
    <p>{`Nagyon sok minden megvalósult, ahogy sok-sok minden maradt erre az évre is, a teendők sosem fogynak el. Van néhány álmunk mely most már évek óta húzódik, nagyon reméljük, hogy ebben idén nagyot tudunk előre lépni. Elsődleges cél most, hogy a rehabilitációs kennelsor elkészüljön. Cicás karanténrészlegünket is szeretnénk bővíteni.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(12, 15)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`2019-ben is nagy hangsúlyt kapott a NOÉ Állatotthon menhelysegítő programja. Célja hogy olyan nehezebb anyagi helyzetben lévő menhelyeknek nyújtsunk segítő kezet, ahol a szándék, a felelős gondolkozás, magatartás megvan, de a lehetősége az anyagi körülmények korlátozottak. Mit mondjunk nagyon nem kell keresgetni, az államilag egyáltalán nem finanszírozott honi állatvédelemben rengeteg segítség elkél. Programunkban elsősorban a kisebb vidéki menhelyeken élő kutyák oltásában, chipezésében és parazitamentesítésében tudtunk segítséget nyújtani. Illetve szukakutyák ivartalanításában, kiemelten az esetleg vemheseket. 2019-ben közel 30 alkalommal kaptak menhelyek élelmet, oltásokat, chipet. 2018-tól bekerült a programba a kombinált oltás is. Támogatott menhelyeink a teljesség igénye nélkül: Püspökladány, Törökszentmiklós, Kisvárda, Nyírmada, Ózd, Mezőkövesd, Békéscsaba, Orosháza az Ebremény.`}</p>
    <p>{`A program új eleme a karanténozási és fertőtlenítési fejlesztés. Külső pályázati forrást - MagnetBank KAP pályázat - is bevonva, több mint 1millió forint értékben tudtunk professzionális Sagewash induló csomagot (pisztoly és fertőtlenítő kapszula) biztosítani 11 vidéki menhelynek. Terveink szerint 2020-ban újabb elemmel bővül a támogatási csomagunk. Terveink most a környezet és természetvédelemmel összefüggésben az élő árnyékolás, menhelyzöldítés, faültetéssel kapcsolatosak.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(15, 18)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Sok minden közepette mégis a fő dolog az állatok mentése. Idén is bőven 1.000 felett volt a bekerülő állatok száma. A többségük persze kutyus és cicus, de bőven akadtak egyéb teremtmények is. Rengeteg egészen apró, állandó gondoskodást igénylő. Konkrétan voltak olyan hónapjai az évnek, hogy minden szabad kézben cumi, fecskendő, csipesz volt, egy-egy apróság táplálásához.`}</p>
    <p>{`Volt sok szívszorító eset, amikor magunk sem hittük a látottakat. A bekerülő és a nálunk élő védenceinkről szóló hírek elolvashatóak ITT: `}<a href="http://www.noeallatotthon.hu/index.php?pid=2&tid=4" target="_blank" rel="noreferrer">{`Történetek Állatainkról`}</a></p>
    <p>{`Közel 900 állatot sikerült meggyógyítanunk, felnevelnünk és új családba juttatnunk, illetve repatriálnunk. A többiek a NOÉ Állatotthon végleges, vagy ideiglenes lakói lettek.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(18, 21)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Sok volt a könny, túl sok. Nagyon sokan kerültek be már menthetetlen állapotban és az elöregedő állományunk miatt több régi, idős védencünket elvesztettük. Ezek a legborzasztóbb pillanatok, amit nem lehet megszokni, amit nem lehet feldolgozni, csak a többiek miatt tovább kell lépni ... de mindannyiuk a legkisebbtől a legnagyobbig, a legújabbtól a legrégebbiig bennünk élnek tovább...`}</p>
    <p>{`2020`}{`.`}{` január 1-én az alapítvány védenceinek száma 1.216 volt.`}</p>
    <p>{`2019-ben tovább romlott a helyzet szívférgesség fronton, azt mondhatom, hogy 10 bekerülő kutyából most már inkább 3, mint 2 pozitív szív- vagy bőrféregre. A szűrés, diagnosztizálás és gyógykezelés elképesztően sokba kerülnek. Ez a betegség erősen negatív irányban befolyásolta az állatorvosi költségeinket. De jó hír a rosszban, hogy sikeresen vesszük fel a harcot és úgy tűnik, hogy megvannak a működő gyógyítási protokollok, így egyre nagyobb az esély a teljes gyógyulásra. Ki szívműtéttel, ki injekciós kezeléssel, mások lassabb gyógyszeres terápiával, de sikeresen gyógyulgatnak. Szerencsések vagyunk a legjobb szakorvosok állnak védenceink rendelkezésére, így több gyógyult és gyógyulófélben lévő kutyánk is van.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(21, 24)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`2017`}{`.`}{` január 1-től már Vas megyében is jelen vagyunk. Osztrák állatvédő alapítványtól vettük át a szergényi 100 férőhelyes kutyamenhely és a hozzá tartozó, európai színvonalú celldömölki karanténrészleg üzemeltetését. A menhelyen elsősorban a környék rosszul tartott kutyái és a közeli gyepmesteri telepek halálos ítélete elől mentett állatok élhetnek, hatalmas kennelekben, békében, boldogságban, biztonságban. 2019-re letisztult az alapítványon belül a szergényi menhely elsődleges feladatai. Amikor Budapest környékén tombolnak a fertőző betegségek, akkor ott biztonságban tudnak cseperedni a kölyökkutyák. És egyfajta rehabilitációs részleggé vált, nehezen kezelhető, félős kutyáink számára. A nyugodt, nem zsúfolt közeg és a kisebb állatlétszám lehetővé teszi, hogy nagyon nagy sikerrel gyógyuljanak az összetört kutyaszívek. Sok-sok sikertörténet, gazdásodás bizonyítja mindezt.`}</p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(24, 27)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p>{`Hogy ennyi állat megmenekülhetett, meggyógyulhatott, hogy ennyi álmunk valóra válhatott ezt nagy részben köszönhetjük azoknak a támogatóinknak, akik legutóbb is nekünk ajánlották fel személyi jövedelemadójuk 1%-át. Hálásan köszönjük Nektek, hogy ismét mellénk álltatok és a felajánlásotokkal újra biztos alapot teremtettek a a jövőbeli munkánk hoz.`}</p>
    <p>{`Sok minden megvalósult, sok dolog még álom. De érezve a támogatást, a bizalmat, nem dobjuk el az álmainkat a terveinket. Szépen sorban haladunk, tervezünk, számolunk. És talán egyszer ennek a listának is a végére érünk. De addig még egy-két apróságot megvalósítanánk. Ami a legfontosabb, hogy a megkezdett úton haladva szeretnénk biztosítani a védenceik biztos jövőjét, a kényelmüket, jólétüket, egészségügyi ellátásukat.`}</p>
    <p><strong parentName="p">{`Köszönjük mindenkinek, aki hozzájárult a munkánkhoz, önkéntesként, támogatóként, cégként vagy magánszemélyként, a személyi jövedelemadója 1%-nak felajánlásával, adománnyal, virtuális örökbefogadással, örökbefogadással, ideiglenes befogadással, az adomány vonal hívásával, vagy akár csak egy megosztással!`}</strong></p>
    <Gallery resources={props.data.allJson.nodes[0].resources.slice(27, 30)} nodes={props.data.allFile.nodes} mdxType="Gallery" />
    <p><strong parentName="p">{`CSAK VELETEK SIKERÜLHET!`}</strong></p>
    <p><strong parentName="p">{`KÖSZÖNJÜK!`}</strong></p>
    <p>{`Hála Nektek, akik Noé Állatotthon Alapítvány védencei javára ajánlottátok fel a személyi jövedelemadótok 1 százalékát, újabb 1 évig biztosítva van az 1.200 védencünk - közülük évente mintegy 900 új bekerülő - ellátása, gyógyítása, gazdásítása vagy repatriálása. Keressük a megfelelő szavakat, hogy immár 28. éve álltok mellettünk, segítetek minket és a NOÉs állatokat. A segítségetekkel évente közel 1.000 életet tudunk megmenteni és számukra új esélyt adni az ÉLETRE!`}</p>
    <p>{`A NAV tájékoztatása szerint a 2019-as személyi jövedelemadók 1%-os felajánlásából, összesen 76 112 357 Ft összegben, 14.207 felajánló rendelkezett úgy, hogy biztos hátteret nyújt az Alapítvány gondozásában élő több mint 1.200 állatnak a következő egy évben.`}</p>
    <p>{`Óriási az összeg és ezzel hatalmas a felelősségünk. Továbbra is kizárólag az állatok segítésére, mentésére, ÉLETére fordítunk minden fillért! És, ahogy már nagyon sok éve, nem "csak" a NOÉs védencek részesülnek mindebből, de folytatjuk a kisebb menhelyek segítését, oltást, chipet, élelmet, ivartalanítást, parazitamnetesítést kap további rászorüló nagyjából 2.000 állat. És országosan vállajuk sérült, beteg, balesetes, életveszélyben lévő állatok gondozásunkba vételét.`}</p>
    <p>{`Edukációs tevékenységünk keretében mindenki számára elérhető, ingyenes oktatási programunk szeretettel várja óvodások, iskolások, csoportok látogatását és mi magunk is rendszeresen kijárunk tanintézményekbe előadást tartani a felelős állattartásról, a természet és az állatok tiszteletéről, szeretetéről.`}</p>
    <p>{`Bár ez az összeg egy biztos háttérországot jelent, nagyjából a fele annak, amennyi a biztonságos működésünkhöz, a mentésekhez, az állatok legmagasabb szintű ellátásához szükséges. A másik felét a következő egy évben is közösen kell előteremtenünk. Továbbra is reméljük, hogy velünk és a védenceinkkel maradtok, akiknek csak a közös segítségünk jelenti a túlélést.`}</p>
    <div className="w-full lg:w-auto grow flex flex-col mx-2 p-4 bg-white not-prose">
  <Youtube resources={thumbData.resources} nodes={props.data.video.nodes} mdxType="Youtube" />
    </div>
    <p>{`Korábbi beszámolóink:`}</p>
    <p>
  <Link to="/erre-forditjuk-2018" mdxType="Link">Erre fordítjuk - 2018</Link>
    </p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      